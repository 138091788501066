.social{
    display: flex;
    flex-direction: row;

    &--link-wrapper{
        display: flex;
    }
    &--link{
        display: flex;
        svg{
            margin: auto auto auto 20px;
        }
    }
}