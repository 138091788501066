

.book-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100vh;
  max-width: 900px;
  min-width: 320px;
  height: auto;
  max-height: 100vh;
  z-index: 2000;
  visibility: hidden;
  backface-visibility: hidden;
  transform: translateX(-50%) translateY(-50%);
  overflow: auto;
  &--image {
    width: 100%;
    margin: auto;
  }
  &--content {
    padding: 50px 30px;
    background: bisque;
    color: black;
    p{
  font-size: 1.1rem;

    }
  }
  &--content-wrapper {
    display: flex;
    flex-direction: column;
    // background: bisque;
    // color: black;
  }
  &--text-content{
    p{
        font-weight: 600;
        line-height: 20px;
    }
  }
  .close-button {
    padding: 0;
    height: auto;
    width: auto;
    margin: 0;
    background: none;
    border: 0;
    outline: 0;
    cursor: pointer;
    position: fixed;
    top: 4px;
    right: 8px;
    svg {
      height: 40px;
      width: 40px;
    }
  }
  .purchase-links {
    display: flex;
    // flex-wrap: wrap;
    flex-direction: column;
    .purchase-link-wrapper {
      padding: 10px;
      background-color: #333;
      margin: 10px;
    }
  }
}

.md-show {
  visibility: visible;
}

.md-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  visibility: hidden;
  top: 0;
  left: 0;
  z-index: 1000;
  opacity: 0;
  background: rgba(40, 43, 49, 0.8);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.md-show ~ .md-overlay {
  opacity: 1;
  visibility: visible;
}

/* Content styles */
.book-modal--content {
  color: #333;
}

/* Effect 1: Fade in and scale up */
.md-effect-1 .book-modal--content {
  -webkit-transform: scale(0.7);
  -ms-transform: scale(0.7);
  transform: scale(0.7);
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.md-show.md-effect-1 .book-modal--content {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}
@media only screen and (min-width: 600px) {
  .book-modal {
    &--content{
        padding: 30px;
    }
    &--title{
        margin-top: 0;
    }
    &--content-wrapper {
      display: flex;
      flex-direction: row;
      // background: bisque;
      // color: black;
    }
    &--image {
        width: 325px;
      }
      &--text-content{
        padding: 0 0 0 20px;
      }

    .purchase-links {
        margin-top: 15px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      .purchase-link-wrapper {
        padding: 10px;
        background-color: #333;
        margin: auto;
      }
    }
  }
  .close-button{
    position: absolute;
    top: 0px;
    right: 0px;
  }
}
