.book-section {
    width: 100%;
    max-width: 1472px;
    margin: 50px auto;
    background-color: rgba(255, 228, 196, 0.6666666667);
    padding: 34px 23px;
    // opacity: .7;
    &--header{
        text-align: center;
        color: #333;
        font-size: xx-large;
        font-weight: 800;
        padding-bottom: 20px;
    }
    &.dark{
        .book-section--header{
            color:bisque
        }
        background-color: #222b;
    }
  &--books {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    // max-width: 1270px;
    margin: auto;
    justify-content: center;
    color: black;
  }
}
