.news {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: rgba(34, 34, 34, 0.7333333333);
  color: bisque;
  &--content{
    display: flex;
    flex-direction: column;
    max-width: 1500px;
    margin: auto;
    padding:15px;
  }
}
@media only screen and (min-width: 721px) {
  .news {
    flex-direction: column;
    padding: 38px;
    margin-top: 128px;
    &--header{
      font-size: 3rem;
    }
  }
}
