.main-nav{
    position: absolute;
    height: 100vh;
    width: 100vw;

    // background-color: rgba(0, 0, 0, 0.452);
    .links-wrapper{
        display: flex;
        flex-direction: column;
        padding: 10px;
        .nav-link{
            font-size: 4rem;
            padding: 2px;
            text-decoration: none;
            font-weight: 600;

        }
    }
}

@media only screen and (min-width: 600px) {
    .main-nav{
        position: relative;
        height: auto;
        display: flex;
        flex-direction: row-reverse;
        .links-wrapper{
            flex-direction: row;
            padding: 10px;
            .nav-link{
                padding: 10px;
                font-size: x-large;

            }
        }
    }
  }