// @import url('https://fonts.googleapis.com/css?family=Lobster');
.slick-slider{
    width: 90%;
    margin: auto;
    max-width: 330px;
    @media only screen and (min-width: 721px) {
        max-width: 1500px;
    }
}
.targert_class{
    margin: 20px;
    padding: 20px;
    background-color: aqua;
}

.image-item{
    // width: 100%;
    // padding: 30px;
    display: inline-flex;
    .book-cover{
        transform: translate(0, -100%);
        margin: auto;
        width: 256px;
    }
    .hover-cover{
        z-index: 9;
        background-color: rgba(34, 34, 34);
        position: relative;
        height: 100%;
        width: 256px;
        margin: auto;
        opacity: 0;
        a{
            display: flex;
            width: 100%;
        }
        &:hover{
            opacity: 1;
            background-color: rgba(34, 34, 34, .5);
            transition: cubic-bezier(0.075, 0.82, 0.165, 1); 
        }
        .hover-icon{
            position: absolute;
            width: 100px;
            height: 100px;
            left: calc(50% - 50px);
            bottom: 50px;
        }
    }
}
.carousel{
    &::before{content: '';
    display: block;
    height:      75px;
    margin-top: -75px;
    visibility: hidden;}
    // background-image: radial-gradient( rgba(10,10,10,1), rgba(10,10,10,.6), rgba(100,100,100,0) );
    .slick-dots li button:before{
        font-size: 15px;
        color: bisque;
        opacity: .6;
    }
    .slick-dots li.slick-active button:before{
        opacity: 1;
    }
    .slick-dots li.slick-active button:hover{
        opacity: 1;
    }
   
}

// body {
//     background-color: #89FAD0;
//     font-family: 'Lobster';
// }

// #carousel {
//     position: absolute;
//     height: 200px;
//     width: 810px;
//     margin: auto;
//     left: 0;
//     right: 0;
//     top: 0;
//     bottom: 0;
// }

// .arrow {
//     position: absolute;
//     width: 30px;
//     height: 30px;
//     background-color: white;
//     text-align: center;
//     font-size: 25px;
//     border-radius: 50%;
//     cursor: pointer;
//     font-size: 20px;
//     color: #228291;
//     line-height: 30px;
//     margin-top: 85px;
//     z-index: 1000;
// }

// .arrow-right {
//     left: 780px;
// }

// .item {
//     text-align: center;
//     color: white;
//     font-size: 40px;
//     position: absolute;
//     transition: height 1s, width 1s, left 1s, margin-top 1s, line-height 1s, background-color 1s;
// }

// $level2-height: 150px;
// $level2-width: 110px;
// $level1-height: 180px;
// $level1-width: 130px;
// $level0-height: 200px;
// $level0-width: 150px;

// $level-2-left: 650px;
// $level-1-left: 500px;
// $level0-left: 330px;
// $level1-left: 180px;
// $level2-left: 50px;


// .level-2 {
//     height: $level2-height;
//     width: $level2-width;
//     line-height: $level2-height;
//     background-color: #228291;
//     left: $level-2-left;
//     margin-top: 25px;
// }

// .level-1 {
//     height: $level1-height;
//     width: $level1-width;
//     line-height: $level1-height;
//     background-color: #6796E5;
//     left: $level-1-left;
//     margin-top: 10px;
// }

// .level0 {
//     height: $level0-height;
//     width: $level0-width;
//     line-height: $level0-height;
//     background-color: #4EC9E1;
//     left: $level0-left;
// }

// .level1 {
//     height: $level1-height;
//     width: $level1-width;
//     line-height: $level1-height;
//     background-color: #6796E5;
//     margin-top: 10px;
//     left: $level1-left;
// }

// .level2 {
//     height: $level2-height;
//     width: $level2-width;
//     line-height: $level2-height;
//     background-color: #228291;
//     margin-top: 25px;
//     left: $level2-left;
// }

// .left-enter {
//     opacity: 0;
//     left: $level2-left - $level2-width;
//     height: $level2-height - 30;
//     width: $level2-width - 20;
//     line-height: $level2-height - 30;
//     margin-top: 40px;
// }

// .left-enter.left-enter-active {
//     opacity: 1;
//     left: $level2-left;
//     height: $level2-height;
//     width: $level2-width;
//     line-height: $level2-height;
//     margin-top: 25px;
//     transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s;
// }

// .left-leave {
//     opacity: 1;
//     left: $level-2-left;
//     height: $level2-height;
//     width: $level2-width;
//     line-height: $level2-height;
//     margin-top: 25px;
// }

// .left-leave.left-leave-active {
//     left: $level-2-left + $level2-width + 20;
//     opacity: 0;
//     height: $level2-height - 30;
//     line-height: 120px;
//     margin-top: 40px;
//     width: $level2-width - 20;
//     transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s;
// }

// .right-enter {
//     opacity: 0;
//     left: $level-2-left + $level2-width;
//     height: $level2-height - 30;
//     width: $level2-width - 20;
//     line-height: $level2-height - 30;
//     margin-top: 40px;
// }

// .right-enter.right-enter-active {
//     left: $level-2-left;
//     opacity: 1;
//     height: $level2-height;
//     margin-top: 25px;
//     line-height: $level2-height;
//     width: $level2-width;
//     transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s;
// }

// .right-leave {
//     left: $level2-left;
//     height: $level2-height;
//     opacity: 1;
//     margin-top: 25px;
//     line-height: $level2-height;
//     width: $level2-width;
// }

// .right-leave.right-leave-active {
//     left: $level2-left - $level2-width;
//     opacity: 0;
//     height: $level2-height - 30;
//     width: $level2-width - 20;
//     line-height: $level2-height - 30;
//     margin-top: 40px;
//     transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s;
// }

// .noselect {
//     -webkit-user-select: none; 
//      -khtml-user-select: none; 
//        -moz-user-select: none; 
//         -ms-user-select: none;  
//             user-select: none;
// }