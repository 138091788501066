.contact--info-wrapper{
    color: bisque;
    h1{
        font-size: 3rem;
        color: bisque;
    }
    .contact--info-wrapper-text{
        h2{
            font-size: 2rem;
            max-width: 400px;
        }
        .info-name, .info-email{
            font-size: 1.6rem;
        }
        .info-email{
            text-decoration: underline;
        }
    }
}