.contact {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 120px;
  background-color: #222b;
  padding: 30px;
  &--content-wrapper {
    max-width: 1400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &--form-wrapper {
    background-color: #333;
    padding: 30px;
  }
  &--info-wrapper {
    // color: rgb(71, 60, 47);
  }
  &--input-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0 0 15px 0;
    label {
      font-weight: bold;
      font-size: 1.2rem;
      padding-bottom: 10px;
    }
  }
  label{
    color: bisque;
  }
  input, textarea{
    background-color: bisque;
    border: 0;
    border-radius: 0;
    padding: 7px;
  }
  textarea{
    resize: none;
    height: 100px;
  }
  &--form-button{
    width: 100%;
    margin: 15px 5px 0 5px;
  }
}

@media only screen and (min-width: 600px) {
  .contact {
    flex-direction: row;
    &--input-wrapper{
        padding: 5px;
    }
    &--content-wrapper {
      flex-direction: row;
    }
    &--form-line-1 {
      flex-direction: row;
      display: flex;
      width: 100%;
      .contact--input-wrapper {
        flex: 1;
      }
    }
  }
  .contact-container {
    width: 50%;
    flex: 1;
  }
}
