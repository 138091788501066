.about {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #222b;
  color: bisque;
  &--photo-container {
    flex: 1;
    flex: 1 1;
    width: 100%;
    display: flex;
  }
  &--photo {
    width: 100%;
    max-width: 600px;
    margin: 30px auto;
  }
  &--copy-container {
    max-width: 600px;
    margin: auto;
    padding: 15px;
  }
  &--wrapper{
    width:100%;
    max-width: 1200px;
    margin: auto;
    display: flex;
    flex-direction: column;
    
  }
  &--copy-paragraph {
    font-size: 1.1rem;
    line-height: 1.7rem;
  }
}


@media only screen and (min-width: 721px) {
  .about {
    flex-direction: row;
    padding: 38px;
    margin-top: 128px;
    &--photo-container {
      flex: 1;
      flex: 1 1;
      width: 50%;
      max-width: 700px;
      box-sizing: border-box;
      display: flex;
      img {
        margin: auto;
      }
    }
    &--photo {
      width: 100%;
    }
    &--copy-title {
      font-size: 3rem;
    }
    &--copy-container {
      flex: 1;
      max-width: 800px;
    }
    &--copy-paragraph {
      font-size: 1.1rem;
      line-height: 2.2rem;
    }
    &--wrapper{
      width:100%;
      max-width: 1500px;
      margin: auto;
      flex-direction: row-reverse;
      
    }
  }
}

@media only screen and (min-width: 1217px) {
  .about{&--copy-paragraph {
    font-size: 1.7rem;
    line-height: 2.2rem;
  }}
}
