.top-banner{
    width: 100%;
    height: calc(100vh - 57px);
    background-color: black;
    color: rgb(71, 60, 47);
    text-align: center;
    display: flex;
    background-position: 50% 65%;
    flex-direction: column;
    background-size: cover;
    min-height: 820px;
    position: relative;
    
    // background-image: url(coast.jpeg);
    &--text-wrapper{
        margin: 30px auto;
    }
    &--superscript{
        font-size: 1.5rem;
    }
    &--name{
        padding: 10px;
        font-size: 3rem;
    }
    &--carousel-wrapper{
            &::before{content: '';
    display: block;
    height:      75px;
    margin-top: -75px;
    visibility: hidden;}
        background-image: linear-gradient(rgba(100,100,100,0), rgba(10,10,10,.6), rgba(10,10,10,.6), rgba(100,100,100,0) );
        position: absolute;
        bottom: 30px;
        left: 0;
        width: 100%;
        margin: auto;
        color: #cecea8;
        padding: 75px 0 52px 0px;
        font-size: 1.7rem;
    }
}

@media only screen and (min-width: 600px) {
    .top-banner{
        &--superscript{
        font-size: 2rem;
    }
    &--name{
        padding: 10px;
        font-size: 6rem;
    }
}

}