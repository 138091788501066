.book-item {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  padding: 15px;
  &--image-wrapper {
    margin: auto;
    cursor: pointer;
  }
  &--image {
    width: 300px;
    margin: auto;
  }
  &--cta {
    padding: 10px;
    background-color: black;
    color: bisque;
    font-size: 18px;
    font-weight: 800;
    text-align: center;
  }
}
@media only screen and (min-width: 600px) {
  .book-item {
    &--cta {
    }
  }
}
