.header{
    display: flex;
    position: sticky;
    width: 100%;
    top: 0;
    color: bisque;
    background-color: #222;
    .spacer{
        flex: 1;
    }
    z-index: 9;
    height: 57px;
}
.desktop{
  display: flex;
  width: 100%;
}


 body
 {
   margin: 0;
   padding: 0;
   
   /* make it look decent enough */
   background: #232323;
   color: #cdcdcd;
   font-family: "Avenir Next", "Avenir", sans-serif;
 }
 
 #menuToggle
 {
   display: block;
   position: relative;
   top: 20px;
   left: 20px;
   
   z-index: 1;
   
   -webkit-user-select: none;
   user-select: none;
 }
 
 #menuToggle a:hover
 {
   color: tomato;
 }
 
 
 #menuToggle input
 {
   display: block;
   width: 40px;
   height: 32px;
   position: absolute;
   top: -7px;
   left: -5px;
   
   cursor: pointer;
   
   opacity: 0; /* hide this */
   z-index: 2; /* and place it over the hamburger */
   
   -webkit-touch-callout: none;
 }
 
 /*
  * Just a quick hamburger
  */
 #menuToggle span
 {
   display: block;
   width: 33px;
   height: 4px;
   margin-bottom: 5px;
   position: relative;
   
   background: #cdcdcd;
   border-radius: 3px;
   
   z-index: 1;
   
   transform-origin: 4px 0px;
   
   transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
               background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
               opacity 0.55s ease;
 }
 
 #menuToggle span:first-child
 {
   transform-origin: 0% 0%;
 }
 
 #menuToggle span:nth-last-child(2)
 {
   transform-origin: 0% 100%;
 }
 
 /* 
  * Transform all the slices of hamburger
  * into a crossmark.
  */
 #menuToggle input:checked ~ span
 {
   opacity: 1;
   transform: rotate(45deg) translate(-2px, -1px);
   background: bisque;
 }
 
 /*
  * But let's hide the middle one.
  */
 #menuToggle input:checked ~ span:nth-last-child(3)
 {
   opacity: 0;
   transform: rotate(0deg) scale(0.2, 0.2);
 }
 
 /*
  * Ohyeah and the last one should go the other direction
  */
 #menuToggle input:checked ~ span:nth-last-child(2)
 {
   transform: rotate(-45deg) translate(0, -1px);
 }
 
 /*
  * Make this absolute positioned
  * at the top left of the screen
  */
 #menu
 {
  position: absolute;
  width: 300px;
  margin: -47px 0 0 -20px;
  padding: 64px 25px 50px;
  /* padding-top: 125px; */
  height: 100vh;
  width: 100vw;
  background: #222d;
  color: bisque;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
 }
 
 #menu li
 {
   padding: 10px 0;
   font-size: 22px;
 }
 
 /*
  * And let's slide it in from the left
  */
 #menuToggle input:checked ~ ul
 {
   transform: none;
 }
//  #menuToggle{
//   display: initial;
// }
.desktop{
  display: none;
}

 @media only screen and (min-width: 600px) {
  #menuToggle.mobile-nav{
    display: none;
  }
  .desktop{
    display: flex;
  }
  .header{
    height: 70px;
  }

}