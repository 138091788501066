/* html{
  height: 100vh;
  width: 100vh;
  overflow: hidden;
} */
body {
  margin: 0;
  font-family: Mate;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Mate;
  font-size: 14px;
}

body * {
  box-sizing: border-box;
}
div{
  font-family: Mate;
}
h1 {
  font-family: Mate;
  font-size: 24px;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 26.4px;
}
h3 {
  font-family: Mate;
  font-size: 14px;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 15.4px;
}
p {
  font-family: Mate;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 1.7rem;
    
}
@media only screen and (min-width: 800px){
  p{font-size: 1.7rem;
  line-height: 2.5rem;}
}

blockquote {
  font-family: Mate;
  font-size: 21px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 30px;
}
pre {
  font-family: Mate;
  font-size: 13px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 18.5714px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.small-caps{
  font-family: "Mate SC";
}

a:link { text-decoration: none; 
  color: bisque;
  fill: bisque;
}


a:visited { 
  text-decoration: none; 
  color: #d49b57;
  fill: #d49b57;
}


a:hover { 
  color: #a69279;
  fill: #a69279;
  text-decoration: none; }


a:active { text-decoration: none; }
.offset-scroll-target{
  scroll-margin-top: 100px;
}
